import React from 'react';
import styled from 'styled-components';
import { Divider, BaseButton, BaseLink } from 'aether-marketing';
import PropTypes from 'prop-types';

const Section = styled.section`
background-color: ${(props) => props.backgroundColor || 'white'};
  padding: 30px 0px;
  margin: auto;
    a {
    color: ${(props) => (props.isDark ? '#FF6C37' : '')};
  }
  a:hover {
    color: ${(props) => (props.isDark ? '#FF6C37' : '')};
    border-bottom: 1px solid ${(props) => (props.isDark ? '#FF6C37' : '')};
  }
`;

function SideBySideFooter({ layout, title, body, divider, media, button, anchorId, backgroundColor, link, isDark, logo }) {
  // Determine column widths

  const layoutString = layout.toUpperCase();
  let colText;
  let colMedia;
  switch (layoutString) {
    case '1R':
      colMedia = 'col-lg-6 offset-lg-0 mb-5 mb-lg-0';
      colText = 'col-lg-5 offset-lg-1 col-md-8 offset-md-2';
      break;
    case '1L':
      colText = 'col-12 col-lg-5 order-1 order-lg-0 offset-lg-0 align-items-center';
      colMedia = 'col-10 col-lg-6 order-0 offset-1 order-lg-1 offset-lg-1';
      break;
    default:
      colText = 'col-12 col-lg-5 offset-lg-0 align-items-center';
      colMedia = 'col-12 col-lg-6 offset-lg-1';
  }
  const textSection = (
    <div className={`${colText}`}>
       {logo && (
        <div className="col-lg-12 order-1 order-lg-1 pl-0">
          <img className="img-fluid" alt={logo.alt} src={logo.src} />
        </div>
      )}
      <h2 className="">{title}</h2>
      {body && <div dangerouslySetInnerHTML={{ __html: body.join('') }} />}
      {button && (
        <div className="mb-4 mr-3 d-inline">
          <BaseButton buttonType={button.buttonType} as="a" src={button.src} target={button.target} id={button.id} gaCategory={button.gaCategory} gaLabel={button.gaLabel} className={button.type === 'inline' ? 'pl-0' : null} dataTest={button.id}>
            {button.text}
          </BaseButton>
        </div>
      )}
      {link && (
        <BaseLink linkType="arrowLink" src={link.src} target={link.target ? link.target : 'new-tab-postman'}>
          {link.text}
        </BaseLink>
      )}
    </div>
  );

  const mediaSection = (
    <div className={`${colMedia} mb-3 mb-lg-0`}>
      <img alt={media.alt} src={media.src} className="img-fluid" />
    </div>
  );

  return (
    <>
      <Section id={anchorId} backgroundColor={backgroundColor} isDark={isDark}>
        <div className="container">
          {layoutString === '1R' && (
            <div className="row align-items-center">
              {mediaSection}
              {textSection}
            </div>
          )}
          {layoutString === '1L' && (
            <div className="row align-items-center">
              {textSection}
              {mediaSection}
            </div>
          )}
        </div>
      </Section>
      {divider && <Divider />}
    </>
  );
}

export default SideBySideFooter;
SideBySideFooter.propTypes = {
  layout: PropTypes.string,
  title: PropTypes.string,
  anchorId: PropTypes.string,
  body: PropTypes.arrayOf(PropTypes.string),
  divider: PropTypes.bool,
  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    shadow: PropTypes.bool,
    class: PropTypes.string,
    border: PropTypes.string
  }),
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        text: PropTypes.string,
        src: PropTypes.string,
        target: PropTypes.string,
        id: PropTypes.string,
        gaCategory: PropTypes.string,
        gaLabel: PropTypes.string,
        icon: PropTypes.string
      })
    ),
    PropTypes.oneOf([null])
  ]),
  link: PropTypes.shape({
    linkType: PropTypes.string,
    src: PropTypes.string,
    target: PropTypes.string,
    text: PropTypes.string
  }),
  backgroundColor: PropTypes.string
};

SideBySideFooter.defaultProps = {
  layout: '1L',
  title: null,
  anchorId: null,
  body: null,
  divider: false,
  button: null,
  link: null,
  media: null,
  backgroundColor: null
};
